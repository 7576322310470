@import "./constant.scss";

@for $i from 0 through 100 {
    .border-radius--#{$i} { border-radius: $i * 1px !important; }
    .h--#{$i} {height:$i*5px;}
    .fs--#{$i} { font-size: $i * 1px !important; }

    .pa--#{$i} { padding: $i * 1px !important; }

    .pr--#{$i} { padding-right: $i * 1px !important; }

    .pl--#{$i} { padding-left: $i * 1px !important; }

    .pt--#{$i} { padding-top: $i * 1px !important; }

    .pb--#{$i} { padding-bottom: $i * 1px !important; }

    .plr--#{$i} {
      padding-left: $i * 1px !important;
      padding-right: $i * 1px !important;
    }

    .ptb--#{$i} {
      padding-top: $i * 1px !important;
      padding-bottom: $i * 1px !important;
    }

    .ma--#{$i} { margin: $i * 1px !important; }

    .mr--#{$i} { margin-right: $i * 1px !important; }

    .mt--#{$i} { margin-top: $i * 1px !important; }

    .mb--#{$i} { margin-bottom: $i * 1px !important; }

    .ml--#{$i} { margin-left: $i * 1px !important; }

    .mlr--#{$i} {
      margin-left: $i * 1px !important;
      margin-right: $i * 1px !important;
    }

    .mtb--#{$i} {
      margin-top: $i * 1px !important;
      margin-bottom: $i * 1px !important;
    }
}

.mlr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.m-auto{
  margin:auto;
}
.height100percent {
    height: 100%;
}

.light-bold{
  font-weight: 700;
}

.bold{
  font-weight: 900;
}
.justify-center {
    justify-content: center;
}

.flex {
    display: flex
}

.align-center {
  align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.pos-relative {
    position: relative;
}

.pos-absolute {
    position: absolute;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.color-white{
    color: white;
}

.flex-x {
  display: flex;
  flex-direction: row;
  &.align-center {
      align-items: center;
  }
  &.center {
      align-items: center;
      justify-content: center;
  }
}

.d-center{
  display: flex;
  align-items: center;
}
.overflow{
  overflow:auto
}

.overflow-x{
  overflow-x:auto
}

.row-wrap{
  flex-flow:row wrap;
  justify-content: space-around;
}

.flex-y {
  display: flex;
  flex-direction: column;
  &.center {
      align-items: center;
      justify-content: center;
  }
}

.space-between {
  justify-content: space-between;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.d-flex{
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.full-height {
  height: 100vh;
}

.fill-width {
  width: 100% !important;
}
.fill-half {
  width: 50% !important;
}
.fit-content{
  width:fit-content;
}
.fill-height {
  height: 100% !important;
}

.flex-end {
  justify-content: flex-end;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.text-opacity {
  color: rgba(0,0,0,0.5)
}

.text-center {
  text-align: center;
}

.text-right{
  text-align:right;
}

.text-left{
  text-align:left;
}

.border-0{
  border-width: 0px;;
}
.border-top{
  border-top:1px solid #E3E3E3;
}

.border-right{
  border-right:1px solid #959595;
}

.border-top-red{
  border-top:6px solid #FF848F; 
}

.border-top-orange{
  border-top:6px solid #FFB684; 
}

.border-top-green{
  border-top:6px solid #5EBD71; 
}

.border-bottom{
  border-bottom:1px solid #E3E3E3;
}

.text-gray{
  color:#656565;
}

.text-black{
  color:#000000;
}

.rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.font-regular {
  font-family: $po-font-regular;
}

.font-bold {
  font-family: $po-font-bold;
}

.font-light {
  font-family: $po-font-light;
}

.font-semibold {
  font-family: $po-font-semibold;
}