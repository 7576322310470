.Invoice-preview {
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  overflow-x: auto;
}
.invoice-preview-threecolgrid {
  display: grid;
  grid-template-columns: 5fr 73fr 27fr;
    // grid-template-columns: 50px 830px 300px;
  // grid-template-columns: 50px 830px 300px;
  padding: 2rem 0;
  &__firstele {
    &__icon {
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
  &__secondele {
    .ant-card-body {
      padding: 16px;
    }
  }

  &__thirdele {
    padding-top: 10rem;
  }
}

.invoice-card {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 3px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 0.5rem;
    
    &__imgcontainer {
      display: flex;
      justify-content: flex-end;
    
      &--img {
        height :8rem;
      }
      &--Logoimg {
        height :6rem;
      }
    }
    &__firstele {
      width: 210px;

      &--title {
        margin: 0;
        font-size: 1.3rem;
        font-weight: bolder;
      }
      &--gstin {
        margin: 0;
        font-size: 0.9rem;
        font-weight: bolder;
      }

      &--text {
        line-height: 1.2;
        margin-top: 8px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.45);
      }
      &--pan {
        margin: 0;
        font-size: 0.9rem;
        font-weight: bolder;
      }
    }
    &__secondele {
      &--title {
        margin: 0;
        font-size: 1.1rem;
        font-weight: bolder;
      }
      &--text {
        text-align: right;
        line-height: 1.2;
        margin-top: 8px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
  &__seller {
    margin: 0;
    font-size: 1rem;
    font-weight: bolder;
    color: rgba(0, 0, 0, 0.45);
    text-decoration-line: underline;
    text-decoration-color: rgb(85, 83, 83);
  }
  &__buyer {
    font-weight: bolder;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.45);
    // margin-top:0.2rem;
    text-decoration-line: underline;
    text-decoration-color: rgb(66, 66, 66);;
  }
  &__threecolgrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 0.25rem;
    margin-top: -0.75rem;

    &__ele {
      //   display: flex;
      //   align-items: flex-start;
      display: grid;
      grid-template-columns: 35fr 65fr;
      column-gap: 0.5rem;

      &__label {
        //   width: 40%;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.45);
      }
      &__text {
        font-weight: bolder;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 0.3rem;
      }
    }
  }
  &__tablecontainer {
    // padding: 0.5rem 0 0 0;
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    // margin-top: 1.5rem;
  }
  &__tablegrid {
    display: grid;
    grid-template-columns: 5fr 15fr 11fr 7fr 10fr 11fr 15fr 8fr 10fr 10fr;

    &__label {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 0.25rem;
    }
    &__text {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 0;
    }

    &__description {
      font-weight: bold;
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.45);
      word-break: break-word;
    }
  }

  &__footerthreecolgrid {
    margin-top: 0.75rem;
    display: grid;
    grid-template-columns: 28fr 15fr 27fr 30fr;

    &__label {
      margin: 0;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.45);

      &--alignright {
        justify-self: flex-end;
        text-align: end;
      }
    }
    &__text {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      &--mb {
        margin-bottom: 2rem;
      }
    }
    &__secondele {
      display: flex;
      column-gap: 0.5rem;
      justify-content: flex-end;
    }
    &__thirdele {
      &--container {
        display: grid;
        grid-template-columns: 60fr 40fr;
        column-gap: 2rem;
        margin-bottom: 0.1rem;
      }
    }
  }
}
.footnote {
  text-align: center;
  margin: 2rem 0 0 0;
  font-size: 12px;
  font-weight: bold;

  &__anchor {
    color: #636b73;
    text-decoration: underline;
    // border-bottom: 1px solid #636b73;
  }
}
.invoice-summary-container {
  border-top: 2px solid rgba(0, 0, 0, 0.85);
  border-bottom: 2px solid rgba(0, 0, 0, 0.85);
  padding: 0.4rem 0;
  margin-top: 0.5rem;

  &__text {
    margin: 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    // padding-right: 3.5rem;
  }
  &__amtlabel {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bolder;
    text-align: center;
  }
}
.invoice-generate {
  &__ele {
    display: grid;
    grid-template-columns: 12fr 73fr 15fr;
    align-items: center;
    column-gap: 0.5rem;
    padding-left: 0.5rem;
    &__icon {
      font-size: 1.2rem;
    }
    &__input {
      width: 100%;
      padding: 6px 10px;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.5);
    }
    &__checkbox {
      text-align: center;

      &--margin-bottom {
        margin-bottom: 1.5rem;
      }
    }

    &--margin-bottom {
      margin-bottom: 1.5rem;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: rgba(0, 0, 0, 0.4);
      border-color: rgba(0, 0, 0, 0.4);
    }
  }

  &__btnele {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 2rem;
    column-gap: 1.5rem;
    row-gap: 1rem;

    &__btn {
      color: white;
      background-color: #4532bf;
      border-radius: 5px;
      font-weight: bold;
      &--bg {
        background-color: #d9ffff;
        color: #4532bf;
      }
    }

    &__textele {
      grid-column: 1 / span 2;
      text-align: end;

      &--text {
        font-size: 1rem;
        color: #4532bf;
        border-bottom: 1px solid #4532bf;
        line-height: 0.9;
        display: inline-block;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}
.invoice-generate-success {
  animation: moveInFromRight .3s ease-in-out;
  &__text {
    font-size: 1.2rem;
    text-align: center;
    color: #1ead53;
    font-weight: bold;
    &:hover{
      cursor: pointer;
    }
  }

  &__btnele {
    width: 100%;
    margin: 3rem 0;
    text-align: center;
  }
  &__btn {
    color: white;
    background-color: #4532bf;
    border-radius: 5px;
    font-weight: bold;
  }

  &__printele {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 2rem;
    padding: 0 0.5rem;
    &__text {
      font-size: 1rem;
      color: #4532bf;
      border-bottom: 1px solid #4532bf;
      line-height: 0.9;
      display: inline-block;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.invoice-print{
  &__printele {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2rem;
    padding: 0 0.5rem;
  &__text {
    font-size: 1rem;
    color: #4532bf;
    display: inline-flex;
    font-weight: bold;
    cursor: pointer;
  }
  }
}

.pointer-events-none {
  pointer-events: none;
}
//Animations
@keyframes moveInFromRight {
  0% {
    transform: translateX(10rem);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
  
}
.IRNgenerateModal{
  .ant-modal-title{
    color:rgb(218, 10, 10);      
  }
  .ant-modal-header{
    border-bottom: 1px solid #ebc5c5;
    padding:1rem;
    margin-left:0.5rem;
    margin-right:0.5rem;
  }
  .ant-modal-footer{
    padding: 10px 16px;
    border-top:0px;
  }
  .ant-modal-content{
    margin:0 17rem 0 17rem;
  }
}
.buttonProceed{
  background-color: rgb(70, 46, 144);
  border-color:#4532bf;
  color:#fff;
  font-weight: bold;
  border-radius:4px;
}
.buttonCancel{
  background-color:rgb(255, 95, 79);
  border-color:rgba(235, 75, 26, 0.87);
  color:#fff;
  font-weight: bold;
  border-radius:4px;
}
.ant-card {
  font-size: 13px;
}