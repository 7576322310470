.ewaybill-preview {
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  overflow-x: auto;
}
.ewaybill-preview-threecolgrid {
  display: grid;
  grid-template-columns: 5fr 73fr 27fr;
  // grid-template-columns: 50px 830px 300px;
  // grid-template-columns: 50px 900px 300px;
  padding: 2rem 0;
  &__firstele {
    &__icon {
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
  &__secondele {
    .ant-card-body {
      padding: 24px;
    }
  }

  &__thirdele {
    padding-top: 10rem;
  }
}

//Third colum
.ewaybill-preview-generate {
  &__ele {
    display: grid;
    grid-template-columns: 12fr 73fr 15fr;
    align-items: center;
    column-gap: 0.5rem;
    padding-left: 0.5rem;
    &__icon {
      font-size: 1.2rem;
    &--margin-bottom {
      margin-bottom: 1.5rem;
    }
    }
    &__input {
      width: 100%;
      padding: 6px 10px;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.5);
    }
    &__checkbox {
      text-align: center;
      &--margin-bottom {
        margin-bottom: 1.5rem;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: rgba(0, 0, 0, 0.4);
      border-color: rgba(0, 0, 0, 0.4);
    }
  }
  &__btnele {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 2rem;
    column-gap: 1.5rem;
    row-gap: 1rem;

    &__btn {
      color: white;
      background-color: #4532bf;
      border-radius: 5px;
      font-weight: bold;
      &--bg {
        background-color: #d9ffff;
        color: #4532bf;
      }
    }

    &__textele {
      grid-column: 1 / span 2;
      text-align: end;

      &--text {
        font-size: 1rem;
        color: #4532bf;
        border-bottom: 1px solid #4532bf;
        line-height: 0.9;
        display: inline-block;
      }
    }
  }
}
.ewaybill-preview-card {
  &__header {
    margin-bottom: 3rem;

    &__text {
      text-align: center;
      font-size: 1.3rem;
      font-weight: bold;
    }
    &__img {
      width: 180px;
    }
    &__imgcontainer {
      display: flex;
      justify-content: center;
    }
  }

  &__twocolgrid {
    // min-width: 1500px;
    padding: 0 2rem 0 10rem;
    display: grid;
    grid-template-columns: 35fr 65fr;
    // column-gap: 3rem;

    &__label {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.45);
    }
    &__text {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &__subheading {
    font-size: 1.3rem;
    font-weight: bolder;
    padding-left: 10rem;
    margin-top: 1rem;
  }

  &__fivecolgrid {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 0.5fr 1.5fr 1fr;
    column-gap: 1rem;
    padding: 0 2rem 0 10rem;
    &__label {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.45);
    }
    &__text {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.ewaybill-preview-generate-success {
    animation: moveInFromRight .3s ease-in-out;
    &__text {
      font-size: 1.2rem;
      text-align: center;
      color: #1ead53;
      font-weight: bold;
    }
  
    &__btnele {
      width: 100%;
      margin: 3rem 0;
      text-align: center;
    }
    &__btn {
      color: white;
      background-color: #4532bf;
      border-radius: 5px;
      font-weight: bold;
    }
  
    &__cancelele {
      display: flex;
      align-items: center;
      justify-content: center;

      &__text {
        font-size: 1rem;
        color: #4532bf;
        border-bottom: 1px solid #4532bf;
        line-height: 0.9;
        display: inline-block;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }