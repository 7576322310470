
.items {
    &__add {
      display: flex;
      align-items: center;
      column-gap: 3rem;
      margin-top: 2rem;
  
      &__btn {
        color: white;
        background-color: #462e90;
        border-radius: 5px;
        outline: solid 1px;
        font-weight: bold;
        padding: 0 1.5rem;
  
        &--bg {
          background-color: white;
          color: #462e90;
        }
      }
    }
  
    &__search {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      &__bar {
        width: 30%;
        display: flex;
        align-items: center;
        column-gap: 3rem;
      }
  
      &__delete {
        &--btn {
          color: #462e90;
          border-radius: 5px;
          outline: solid 1px;
        }
      }
    }
    &__table {
      margin-top: 1rem;

      &__subtitlebox {
        
    //    display: flex;
    //    justify-content: flex-end;
// min-width: 768px;


       &--text {
        margin-left: auto;
        color:black;
        width: 24.5%;
        margin-bottom: 0.2rem;
        // overflow-x: auto;

       }
    }
    }
  }
  @media (max-width: 768px) {
    .items {
      &__add {
        flex-direction: column;
        row-gap: 0.5rem;
        align-items: flex-start;
      }
      &__search {
        margin-top: 2rem;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 0.5rem;
        &__bar {
          width: 90%;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 0.5rem;
        }
      }
    }
  }
  
  .iconContainer {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
  
    &__item {
      margin: 0 10px 0 0;
      cursor: pointer;
  
      &:hover {
        color: #462e90;
      }
    }
    &__disabledIcon {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  //Bulk Upload Modal
  .bulkUploadModal {
    .ant-modal-footer {
      padding: 0;
    }
    .ant-modal-close-x {
      display: none;
    }
    .ant-modal-body {
      width: 40rem;
      padding: 2rem;
    }
  }
  @media (max-width: 768px) {
    .bulkUploadModal {
      .ant-modal-body {
        width: 100%;
        padding: 2rem 1.5rem;
      }
    }
  }
  .uploadSection {
    transition: all 0.3s ease-in-out;
    &__title {
      font-size: 1.2rem;
      text-align: center;
    }
  
    &__done {
      margin-top: 3rem;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      &__text {
        color: #008080;
      }
    }
  
    &__download {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      &__text {
        color: #462e90;
        border-bottom: 1px solid #462e90;
        line-height: 0.9;
        font-weight: bold;

        &--btn {
          color: #462e90;
          margin-bottom: 0px;
          border-bottom: 1px solid #462e90;
          line-height: 0.9;
          font-weight: bold;

          &:hover {
            cursor: pointer;
          }
        }
  
        &:hover {
          color: #462e90;
        }
      }
      &__btn {
        color: white;
        border-radius: 5px;
        font-weight: bold;
      }
    }
  }
  .fileupload-section {
    animation: moveInFromLeft .3s ease-in-out;
    .ant-upload.ant-upload-drag {
      width: 50%;
      margin: 0 auto;
      padding: 2rem 0;
    }
    &__title {
      text-align: center;
    }
  
    &__dragger {
      margin-top: 2rem;
      &__text {
        color: rgba(0, 0, 0, 0.45);
        font-size: 1.5rem;
        text-align: center;
      }
    }
  
    &__browse {
      margin-top: 1rem;
      // margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
  
      &__btn {
        font-size: 1.2rem;
        font-weight: bold;
        background-color: #462e90;
        color: white;
        border-radius: 5px;
        padding-bottom: 2rem;
      }
    }
  }
  
  @media (max-width: 768px) {
    .fileupload-section {
      .ant-upload.ant-upload-drag {
        width: 80%;
      }
    }
  }
  .success-section {
    animation: moveInFromLeft .3s ease-in-out;
    &__icon {
      text-align: center;
      margin-top: 2rem;
      position: relative;
      z-index: 1;
  
      &::after {
        content: '';
        padding: 1.2rem;
        background-color: #52c41a;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        transform: translate(-50%,-20%);
        border-radius: 50%;
        opacity: 0.3;
      }
    }
    &__text {
      text-align: center;
      margin: 2rem 0;
      font-weight: bold;
    }
    &__btnbox {
      text-align: center;
  
      &__btn {
        background-color: #462e90;
        color: white;
        font-weight: bold;
        border-radius: 5px;
      }
    }
  }
  
  
  //Animations 
  @keyframes moveInFromLeft {
    0% {
      transform: translateX(-10rem);
      opacity: 0;
    }
    100% {
      transform: translate(0);
      opacity: 1;
    }
    
  }

  //custom class

  .no-pointer-events {
    cursor: not-allowed;
    pointer-events: none;
  }
  .itemErrorlistModal{
    .ant-modal-title{
      color:rgb(218, 10, 10);      
    }
    .ant-modal-header{
      border-bottom: 1px solid #ebc5c5;
      padding:1rem;
      margin-left:0.5rem;
      margin-right:0.5rem;
    }
    .ant-modal-content{
      margin:0 3.5rem 0 7rem;
    }
  }