//General Details
.detailscard {
  margin-bottom: 2rem;
  &__threecolgrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
  }
  &__twocolgrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
  }
}
@media (max-width: 768px) {
  .detailscard {
    &__threecolgrid {
      grid-template-columns: 1fr;
    }
    &__twocolgrid {
      grid-template-columns: 1fr;
    }
    .ant-form .ant-form-item .ant-form-item-label,
    .ant-form .ant-form-item .ant-form-item-control {
      flex: 0 0 50%;
    }
  }
}
@media (max-width: 575px) {
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 50%;
  }
}
.dragger {
  width: 35%;
  &__text {
    color: #462e90;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.5rem 0;
  }
}
@media (max-width: 768px) {
  .dragger {
    width: 70%;
    // margin: 0 auto;
  }
}
.radiotext {
  font-weight: bold;
}
.bank-details-card {
  transition: all 0.3s ease-in-out;
  position: relative;
  box-shadow: 0px 0px 4px #BFBFBF29;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  &__closeicon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
  }
}
@media (max-width: 768px) {
  .bank-details-card {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
.certificate-section {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;

    &__ele a {
      display: inline-block;
      font-weight: bold;
      line-height: 0.9;
      color: #462e90;
      border-bottom: 1px solid #462e90;
    }
  }
}
@media (max-width: 768px) {
  .certificate-section {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
//Whatsapp Modal
.whatsappModal {
  .ant-modal-footer {
    padding: 0;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    width: 40rem;
    padding: 2rem;
  }
}
@media (max-width: 768px) {
  .whatsappModal {
    .ant-modal-body {
      width: 100%;
      padding: 2rem 1.5rem;
    }
  }
}
.addanotherbanktext {
  display: inline-block;
  font-weight: bold;
  line-height: 0.9;
  color: #462e90;
  border-bottom: 1px solid #462e90;
  cursor: pointer;
}

.whatsappSection {
  // transition: all 0.3s ease-in-out;
  &__title {
    font-size: 1rem;
    text-align: center;
  }
  &__getupdate {
    margin-top: 3rem;
    text-align: center;

    &__btn {
      color: white;
      border-radius: 5px;
      font-weight: bold;
    }
  }
  &__nosection {
    margin-top: 1.5rem;
    text-align: center;

    &__text {
      display: inline-block;
      color: #462e90;
      border-bottom: 1px solid #462e90;
      line-height: 0.9;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
//Invoice setting
.invoice-settings-section {

  &:not(:last-child){
    margin-bottom: 2rem;
  }
}
.invoice-settings-card {
  &__header {
    display: flex;
    align-items: center;
    column-gap: 8rem;

    &__text {
      margin: 0;
    }

    &__subtext {
      margin: 0 0 0 5px;
      // font-size: 1rem;
      line-height: 1;
    }
    &__colorpickerblock {
      display: flex;
      align-items: center;
    }
  }

  &__twocolgrid {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10rem;
    margin: 2rem auto;
  }

  &__preview {
    border: 1px solid #ccc;
    height: 226px;
    border-radius: 2px;
    transition: all .3s ease-in-out;
    padding: 5px 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__firstele {
      width: 70%;
      display: flex;
      align-items: center;
      column-gap: 1rem;
      margin-top: 5px;

      &--text {
        margin: 0;
      }
      &--input {
        width: 8rem;
      }
      &--subtext {
        margin: 0;
        font-size: 13px;
        color: #1890ff;
      }
    }
    &__secondele {
      width: 30%;
      text-align: center;

      &--cta {
        display: inline-block;
        color: #462e90;
        font-size: 1rem;
        font-weight: bold;
        border-bottom: 1px solid #462e90;
        line-height: 0.9;
        margin-right: 3.8rem;
        cursor: pointer;
      }
    }
  }
}
@media (max-width:768px){
  .invoice-settings-card {

  
    &__twocolgrid {
      width: 100%;

      grid-template-columns: 1fr;
      row-gap: 2rem;

    }
    &__footer {
      flex-direction: column;
      row-gap: 2rem;
    &__firstele { 
      width: 100%;
      column-gap: 0.5rem;
      &--text {
        width: 20%;
      }
      &--input {
        width: 30%;

      }
      &--subtext {
        width: 50%;

      }
  
    }
    &__secondele { 
      width: 100%;
      &--cta {

        margin-right: 0rem;

      }
    }
  }
  
  }

}
.color-picker {
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 0;
  box-shadow: none;
}
.active-border {
  // border: 3px solid #462e90;
  box-shadow: inset 0 0 0 3px #462e90;
}
.email-info-text {
  color: red;
  font-size: 12px;
}

.custom_tabs {
  .ant-tabs-nav {
    .ant-tabs-tab-active {
      color: #002B5C;
    }
    .ant-tabs-ink-bar {
      background-color: #002B5C;
      height: 2px;
    }
  }

}
.container-tabs {
  .ant-tabs-nav {
    .ant-tabs-tab-active {
      color: #7954ED;
    }
  }
  .ant-tabs-ink-bar {
    background-color: #7954ED !important;
    height: 2px;
  }
}
.input-class {
  padding: 8px;
  border-radius: 5px;
}
.profile-section-title {
  color: #333841;
}
.logo-section {
  display: flex;
  align-items: top;
  margin-bottom: 1.5rem;
  column-gap: 2rem;

  &--view {
    display: flex;
    align-items: top;
    column-gap: 1rem;
  }

  &--upload {
    text-align: center;
  }

  button {
    border-radius: 5px;
  }

  .ant-btn:hover {
    color: #462e90;
  }
}
.logo-view-modal {

  .ant-modal-body {
    width: 60rem;
  }
  .ant-modal-footer {
    padding: 0;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-upload-list .ant-upload-list-text {
    display: none;
  }
  .ant-upload-list-item .ant-upload-list-item-done .ant-upload-list-item-list-type-text {
    display: none;
  }

  &--modal {
    width: 40rem;
    height: 30rem;
    margin: 0 auto;
  }
}