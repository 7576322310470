.transporters {
  &__add {
    display: flex;
    align-items: center;
    column-gap: 3rem;
    margin-top: 2rem;

    &__btn {
      color: white;
      background-color: #462e90;
      border-radius: 5px;
      outline: solid 1px;
      font-weight: bold;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      &--bg {
        background-color: white;
        color: #462e90;
      }

    }
  }

  &__search {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__bar {
      width: 23%;
    }

    &__delete {
      &--btn {
        color: #462e90;
        border-radius: 5px;
        outline: solid 1px;
      }
    }
  }
  &__table {
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  .transporters {
    &__add {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
    &__search {
      margin-top: 2rem;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 0.5rem;
      &__bar {
        width: 90%;
      }
    }
  }
}
.iconContainer {
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  &__item {
    margin: 0 10px 0 0;
    cursor: pointer;

    &:hover {
      color: #462e90;
    }
  }
}
.addTransporterModal {
  .ant-modal-footer {
    padding: 0;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    width: 40rem;
    padding: 2rem 2rem 0 2rem;
  }
  .ant-modal-content .ant-input {
    width: 100%;
    background-color: white;
  }
}
@media (max-width: 768px) {
  .addTransporterModal {
    .ant-modal-body {
      width: 100%;
      padding: 2rem 1.5rem 0 1.5rem;
    }
    .ant-modal-content .ant-input {
      width: 100%;
    }
  }
}
.addform {
  &__inputbox {
    margin-top: 1rem;
  }

  &__twoColGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
  }

  &__btnbox {
    display: flex;
    justify-content: end;
    align-items: center;
    column-gap: 2rem;
    margin-top: 2rem;

    &__btn {
      border-radius: 5px;
      font-weight: bold;

      &--paragraph {
        margin-bottom: 0px;
        font-size: 1rem;
        line-height: 0.9;
        border-bottom: 1px solid #462e90;
        cursor: pointer;
        border-radius: 0px;
        transition: all 0.3s ease-in-out;

        // &:hover {
        //     border-bottom: 0px solid transparent ;
        // }
      }
    }
  }
}

//Bulk Upload Modal
.bulkUploadModal {
  .ant-modal-footer {
    padding: 0;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    width: 40rem;
    padding: 2rem;
  }
}
@media (max-width: 768px) {
  .bulkUploadModal {
    .ant-modal-body {
      width: 100%;
      padding: 2rem 1.5rem;
    }
  }
}
.uploadSection {
  transition: all 0.3s ease-in-out;
  &__title {
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
  }

  &__done {
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    &__text {
      color: #008080;
    }
  }

  &__download {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
      color: #462e90;
      border-bottom: 1px solid #462e90;
      line-height: 0.9;
      font-weight: bold;

      &:hover {
        color: #462e90;
      }
    }
    &__btn {
      color: white;
      border-radius: 5px;
      font-weight: bold;
    }
  }
}
.fileupload-section {
  animation: moveInFromLeft .3s ease-in-out;
  .ant-upload.ant-upload-drag {
    width: 50%;
    margin: 0 auto;
    padding: 2rem 0;
  }
  &__title {
    text-align: center;
  }

  &__dragger {
    margin-top: 2rem;
    &__text {
      color: rgba(0, 0, 0, 0.45);
      font-size: 1.5rem;
      text-align: center;
    }
  }

  &__browse {
    margin-top: 1rem;
    // margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__btn {
      font-size: 1.2rem;
      font-weight: bold;
      background-color: #462e90;
      color: white;
      border-radius: 5px;
      padding-bottom: 2rem;
    }
  }
}

@media (max-width: 768px) {
  .fileupload-section {
    .ant-upload.ant-upload-drag {
      width: 80%;
    }
  }
}
.success-section {
  animation: moveInFromLeft .3s ease-in-out;
  &__icon {
    text-align: center;
    margin-top: 2rem;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      padding: 1.2rem;
      background-color: #52c41a;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      transform: translate(-50%, -20%);
      border-radius: 50%;
      opacity: 0.3;
    }
  }
  &__text {
    text-align: center;
    margin: 2rem 0;
    font-weight: bold;
  }
  &__btnbox {
    text-align: center;

    &__btn {
      background-color: #462e90;
      color: white;
      font-weight: bold;
      border-radius: 5px;
    }
  }
}
//Animations 
@keyframes moveInFromLeft {
  0% {
    transform: translateX(-10rem);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
  
}
.custom_table{
  .ant-table-thead > tr > th {
    background: #ffffff;
    border-bottom: 3px solid #f0f0f0;
  }
}
.btn-icon-padding {
  padding-right: 5px;
}

.terms_privacy_modal {
  .ant-modal-footer {
    padding: 0;
  }
  
  .ant-modal-body {
    width: 80%;
    padding: 2rem 2rem 0 2rem;
    margin: auto;
 
  }
  .ant-modal-content .ant-input {
    width: 100%;
    background-color: white;
    height: 400px
  }
  .ant-modal-content {
    width: 75%;
    margin: 0 auto;
    
  }

  .ant-modal-close-x {
    position: fixed;
    top: 0;
    right: 13vw;
    color: #4532bf;
  }

}
@media (max-width: 768px) {
  .terms_privacy_modal {
    .ant-modal-body {
      width: 100%;
      padding: 2rem 1.5rem 0 1.5rem;
    }
    .ant-modal-content .ant-input {
      width: 100%;
    }
  }
}

// .modal_wrapper {
//   .ant-modal-content {
//     width: 80%;
//     margin: 0 auto;
//   }
// }