@font-face {
    font-family: 'po-extrabold';
    src: url('../fonts/Poppins-ExtraBold.ttf');
  }
  
  @font-face {
    font-family: 'po-bold';
    src: url('../fonts/Poppins-Bold.ttf');
  }
  
  @font-face {
    font-family: 'po-semibold';
    src: url('../fonts/Poppins-Medium.ttf');
  }
  
  @font-face {
    font-family: 'po-regular';
    src: url('../fonts/Poppins-Regular.ttf');
  }
  
  @font-face {
    font-family: 'po-light';
    src: url('../fonts/Poppins-Thin.ttf');
  }
  