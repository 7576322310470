.ewaybill-header-container {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  column-gap: 1rem;
  margin-top: 1.5rem;

  &__text {
    margin: 0;
  }
}
.radio-container {
  display: flex;
  column-gap: 2rem;
  align-items: center;
}
@media (max-width:768px){
  .radio-container {
    column-gap: 0.2rem;
  }
}
.transaction-details {
  margin-top: 1rem;

  &__title {
    font-weight: bold;
    &--mt {
      margin-top: 1.5rem;
    }
  }
}

@media (max-width: 768px) {
  .ewaybill-form {
    .ant-form .ant-form-item .ant-form-item-label,
    .ant-form .ant-form-item .ant-form-item-control {
      flex: 0 0 50%;
    }
  }
}
.ewaybill-threecolgrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
}
@media (max-width: 768px) {
  .ewaybill-threecolgrid {
    grid-template-columns: 1fr;
  }
}
.ewaybill-twocolgrid {
  width: 66.5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
}
@media (max-width: 768px) {
  .ewaybill-twocolgrid {
    width: 100%;
    grid-template-columns: 1fr;
  }
}
.ewaybill-fourcolgrid {
  display: grid;
  grid-template-columns: 45fr 23fr 14fr 18fr;
  column-gap: 1rem;
}
@media (max-width: 768px) {
  .ewaybill-fourcolgrid {
    grid-template-columns: 1fr;
  }
}
.tax-rate {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  &__text {
    margin: 0 0 4px 0;
  }
}

.summary-container {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  margin-top: 1rem;
}
.eway-total-twocolgrid {
  display: grid;
  grid-template-columns: 75fr 25fr;
  column-gap: 2rem;
  row-gap: 1.5rem;
  width: 30%;
}
.eway-total-label {
  margin: 0;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  justify-self: flex-end;
  align-self: center;
}
// .doc-type {

// }
@media (max-width: 768px) {
  .summary-container {
    justify-content: flex-start;
  }
  .eway-total-twocolgrid {
    // display: grid;
    grid-template-columns: 70fr 30fr;
    // column-gap: 2rem;
    // row-gap: 1.5rem;
    width: 100%;
  }
  .eway-total-label {
    justify-self: flex-start;
  }
}
.transporter-details-threecolgrid {
  display: grid;
  grid-template-columns: 35fr 30fr 35fr;
  column-gap: 3rem;

  &__secondele {
    align-self: center;
    margin: 0;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
  }
}
@media (max-width: 768px) {
  .transporter-details-threecolgrid {

    grid-template-columns: 1fr;

  }
}
.transporter-doc-date-container {
  display: grid;
  grid-template-columns: 60fr 40fr;
  column-gap: 1rem;
  &__text {
    grid-column: 1 / span 2;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 0.5rem 0;
  }
}
