@import './helper.scss';
@import './reconcilation.scss';
@import './antd-override.scss';
@import './downloadcenter.scss';
@import './font.scss';
@import './eInvoicing.scss';
@import './newInvoice.scss';
@import './eWayBill.scss';
@import './invoicePreview.scss';
@import './eWayBillPreview.scss';
@import './companyProfile.scss';
@import './items.scss';
@import './addItem.scss';
@import './manageCustomers.scss';
@import './addCustomer.scss';
@import './transporters.scss';
@import './GSTINSearch.scss';
@import './GSTINDetails.scss';
@import './signup.scss';

body {
  font-family: 'po-regular';
}

del {
  text-decoration-thickness: 0.15em;
}
//To hide scroll bar in sidenavbar
.hide-scroll {
  scrollbar-color: transparent transparent;

    -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  &::-moz-scrollbar {
    display: none;

  }
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.menu-item-collection {
  margin: 0 10px;
.user-plus-logo {
  filter: invert(34%) sepia(8%) saturate(203%) hue-rotate(201deg) brightness(93%) contrast(87%);
}
.ant-menu-item-selected .user-plus-logo {
  filter: invert(0%) sepia(100%) saturate(16%) hue-rotate(8deg) brightness(95%) contrast(100%);;
}
}
.bxsh{
  box-shadow: #4532bf4d 3px 3px 10px 0px;
}
.gen_upload > div > span >
.ant-upload-list.ant-upload-list-text{
  display: none;
}


.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background-color: #462E90;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
  color:#462E90;
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon{
  color:#444444;
}

.sp_right::after{
  content: ">";
  color:#462E90;
  float: right;
}
.sp_right{
font-size: '15px !important';
}
.ant-btn-primary{
  background-color: #4532bf ;
  border-color: #4532bf;
}



div.spr_card_hr > h3 {
  border: 1px solid #ff4d4f;
  background-color: #ff4d4f;
color:#fff;
text-align: center;
width: 50%;
margin: 0px ;
border-radius: 5px 5px 0px 0px ;

}
.spr_card_hr > div{
  border: 1px solid #ff4d4f;
  border-radius: 0px 5px 5px;
  text-align: center;
  padding: 10px;
}



div.spr_card_mr > h3 {
  border: 1px solid #FFC000;
  background-color: #FFC000;
color:#fff;
text-align: center;
width: 50%;
margin: 0px ;
border-radius: 5px 5px 0px 0px ;

}
.spr_card_mr > div{
  border: 1px solid #FFC000;
  border-radius: 0px 5px 5px;
  text-align: center;
  padding: 10px;
}



div.spr_card_all > h3 {
  border: 1px solid #00B050;
  background-color: #00B050;
color:#fff;
text-align: center;
width: 50%;
margin: 0px ;
border-radius: 5px 5px 0px 0px ;

}
.spr_card_all > div{
  border: 1px solid #00B050;
  border-radius: 0px 5px 5px;
  text-align: center;
  padding: 10px;
}

.spr_card_hr > div > p,
.spr_card_mr > div > p,
.spr_card_all > div > p{
  background-color: #F0EEF9;
  border-radius: 5px;
  margin: 2px;
}
.ant-modal-footer{
  text-align: center;
}
.recon-success > .ant-card-body{
  border-radius: 5px;
  border-top: 3px solid #00B050;
  box-shadow:#4532bf4d 3px 3px 10px 0px;
}


.recon > .ant-card-body{
  border-radius: 5px;
  border-top: 3px solid #FF848F;
  box-shadow:#4532bf4d 3px 3px 10px 0px;
}
.recon-wordspace > .ant-card-body{
  border-radius: 5px;
  border-bottom: 3px solid #B3A7FF;
  background-color: #F5F4FC;
  box-shadow:#4532bf4d 3px 3px 10px 0px;
}
.remove-margin {
  margin: 0;
}

/* hiding zoho chatbot when user logged in */
body{
  &.logged-in{
    .zsiq_floatmain{
      display: none !important;
      visibility: hidden !important;
    }
  }
}