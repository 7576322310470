input {
  &.input {
    &--error {
      border-color: red !important;
    }
  }
}
.ant-modal-content {
  .blue-card {
    background: #4532bf;
    border-radius: 5px;
    color: white;
  }

  .specRow {
    border: 2px solid rgb(212, 212, 212);
    padding: 10px;
    height: 115px;
  }

  .border-bottom {
    border-bottom: 1px solid #e3e3e3;
  }

  .red-btn {
    min-height: 40px;
    background: #ff5f4f;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    font-weight: bold;
    color: #ffffff;
  }

  .disabled-btn {
    min-height: 40px;
    background: #afafaf;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    font-weight: bold;
    color: #646464;
  }

  .search-input {
    display: flex;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    margin: 10px;
    width: 98% !important;
    border: 1px solid #d5d5d5;
    background: transparent;
    :focus {
      border: 1px solid #d5d5d5;
      outline: unset;
    }
  }

  .red-text {
    color: #ff5f4f !important;
  }

  .cell {
    padding: 25px !important;
    font-weight: 700;
  }

  .green-text {
    color: #00b83f;
  }

  .white-btn {
    min-height: 40px;
    background: #ffffff;
    border: 1px solid #ff5f4f;
    border-radius: 5px;
    font-weight: bold;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
    color: #ff5f4f;
  }

  .bl {
    border-left: 1px solid #d4d4d4;
  }

  .br {
    border-right: 1px solid #d4d4d4;
  }

  .bt {
    border-top: 1px solid #d4d4d4;
  }

  .bb {
    border-bottom: 1px solid #d4d4d4;
  }

  .brl {
    border-radius: 5px 0px 0px 5px;
  }

  .brr {
    border-radius: 0px 5px 5px 0px;
  }

  .thead {
    background: rgba(69, 50, 191, 0.15);
    border-radius: 5px;

    th {
      padding: 15px;
      min-width: 110px;
    }

    th:first-child {
      border-radius: 5px 0px 0px 5px;
    }

    th:last-child {
      border-radius: 0px 5px 5px 0px;
    }
  }

  .ant-input {
    background: #f2f2f2;
    width: 170px;
  }

  .ant-select-arrow {
    margin-top: -13px;
  }

  .ant-select-selector {
    background: transparent;
    border: 0px;

    .ant-select-selection-placeholder {
      color: black;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .table {
    width: 100%;
  }

  .tbody {
    .trow {
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
}

// .ant-menu {
//   font-size: 12px;
// }

// .ant-menu-item:active,
// .ant-menu-submenu-title:active {
//   background: none;
// }

// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background-color: #e6f7ff;
//   // border-radius: 50px;
//   margin: auto;
//   display: flex;
//   justify-content: center;
//   margin: auto;
//   color: blue;
//   a:hover {
//     color: black;
//   }
// }

// .ant-layout {
//   background-color: white;
// }

// .ant-layout-footer {
//   padding: 10px 24px;
// }

.image-wrapper {
  min-height: 148px;
  padding: 50px;
}

.ant-layout {
  &-sider {
    overflow: 'auto';
    height: '100vh';
    position: 'fixed';
    left: 0;
    z-index: 10;
    .logo-wrapper {
      height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 32px;
        // transition: width 0.5s ease-in;
      }
    }
    .ant-menu {
      &-item {
        display: flex;
        &::after,
        &::before {
          display: none;
        }
        .menu-item {
          &-text {
            transition: width 0.5s ease-in;
            padding-left: 10px;
            width: 100%;
            overflow: hidden;
            font-size: 14px;
          }
          &-icon {
            min-width: 35px;
            display: flex;
            font-size: 24px;
            align-items: center;
            img {
              max-width: 100%;
            }
          }
        }
        > a {
          display: flex;
        }
        &.ant-menu-item-selected {
          justify-content: flex-start;
        }
      }
    }
    .ant-layout-sider-trigger {
      background-color: #1890ff;
      color: #ffffff;
      text-align: right;
      padding-right: 20px;
      font-size: 24px;
      font-weight: 900;
    }
    &.ant-layout-sider-collapsed {
      // .logo-wrapper {
      //   img {
      //     width: 95%;
      //   }
      // }
      .ant-menu-inline-collapsed {
        width: auto;
        .ant-menu-item {
          padding: 0 10px !important;
          &.ant-menu-item-selected {
            &::after {
              opacity: 0;
            }
          }
          .menu-item-text {
            width: 0;
          }
        }
      }
    }
    .sidebar--toggle-control {
      position: fixed;
      bottom: 0;
      left: 218px;
      font-size: 26px;
      color: #ffffff;
      background-color: #4532bf;
      padding: 0 4px;
      font-weight: bolder;
      border-radius: 0 7px 7px 0;
      width: 30px;
      cursor: pointer;
      transition: all 0.2s;
    }
    &.ant-layout-sider-collapsed {
      .sidebar--toggle-control {
        left: 54px;
      }
    }
  }
}

.ant-tooltip {
  &-content {
    .menu-item {
      &-icon {
        display: none;
      }
      &-text {
        color: #ffffff;
      }
    }
  }
}

.disabled--otp-form {
  .otp-input-wrapper {
    .ant-form-item-control-input-content {
      // position: relative;
      background: url('../images/box.svg') space 0 0;
      background-size: contain;
      background-color: #ffffff;
      input {
        width: 115% !important;
        box-shadow: none;
        letter-spacing: 2.05ch;
        padding-left: 0.85ch;
        padding-right: 2px;
        border: none;
        background: transparent;
        &::after {
          content: '. ';
        }
        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
        & + div {
          position: absolute;
          right: -50px;
          height: 100%;
          width: 15%;
          top: 0;
          background: #ffffff;
        }
      }
    }
    // &.ant-form-item-has-error{
    //   .ant-form-item-control-input-content {
    //     background: url('../images/box-error.svg') space 0 0;
    //   }
    // }
  }

  .ant-form-item-control-input {
    .ant-form-item-children-icon {
      margin-right: -30px;
    }
  }
}

.otp-input {
  margin-left: 10px;

  input {
    font-size: 35px;
    width: 60px !important;
    height: 60px !important;
    background: #ffffff;
    border: 2px solid #000;
    box-sizing: border-box;
    border-radius: 5px;
  }
}

.ant-modal {
  min-width: fit-content;
  .ant-modal-body {
    .ant-modal-confirm-body-wrapper {
      display: flex;
      flex-direction: column;
      .ant-modal-confirm-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        span.anticon {
          transform: scale(2);
          margin-bottom: 32px;
        }
        .ant-modal-confirm-title {
          font-weight: bold;
        }
      }
      .ant-modal-confirm-btns {
        float: none;
        display: flex;
        button {
          min-width: 100px;
          margin: 0 15px;
        }
      }
    }
  }
}

.app {
  &__dashboard {
    .ant-row {
      .ant-col {
        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }
    }
  }
  &__reconciliation- {
    &report {
      .ant-select-selector {
        .ant-select-selection-placeholder {
          color: #ffffff;
          opacity: 1;
        }
      }
    }
    &page {
      .ant-tabs-extra-content {
        // width: 300px;
        line-height: 20px;
        font-size: 20px;
        &--navigator{
          display: flex;
        }
      }
      &--navigator{
          min-width: 180px;
          display: flex;
          justify-content: space-between;
      }
    }
  }
}

.ant-tabs-nav {
  .ant-tabs-tab-active {
    color: #4532bf;
  }
}
.download-gst-r2a-wrapper {
  .image-wrapper {
    min-height: 148px;
    padding: 50px;
    &.upload-success {
      padding: 0;
    }
  }
}

.signup-form {
  &__consent-declaration {
    margin-bottom: 2px;
    white-space: nowrap;
    &.whatsapp-consent {
      margin-bottom: 24px;
    }
  }
  .ant-form-item-control-input-content {
    input {
      border-color: #cacaca;
    }
    .ant-select-selection-placeholder {
      color: rgba(0, 0, 0, 0.85);
    }
    &[name='tradeName'] {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

// ul.ant-menu li.ant-menu-item:last-child .menu-item-icon {
//   transform: rotate(90deg) translateY(5px) translateX(5px);
// }
// ul.ant-menu li.ant-menu-item:last-child .menu-item-icon {
//   transform: rotate(90deg) translateY(5px) translateX(5px);
// }
//ul.ant-menu li.ant-menu-item:last-child .menu-item-icon {
//  transform: rotate(90deg) translateY(5px) translateX(5px);
//}
.rotate-icon {
  transform: rotate(90deg) translateY(5px) translateX(5px);
}

.ant-menu {
  font-size: 12px;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #E0E0FD;
  display: flex;
  color: blue;
  // margin: 0 10px;
  border-radius: 5px;
  a:hover {
    color: black;
  }
}

.ant-layout {
  background-color: white;
}

.ant-layout-footer {
  padding: 10px 24px;
}

.download-gst-r2a-wrapper {
  .image-wrapper {
    min-height: 148px;
    padding: 50px;
    &.upload-success {
      padding: 0;
    }
  }
}

.ant-modal {
  &.modal-conditions {
    height: 80%;
    .ant-modal-content,
    .ant-modal-body {
      height: 100%;
    }
    .ant-modal-body {
      padding: 0;
      iframe {
        border: none;
      }
    }
    .ant-modal-close-x {
      width: 28px;
      height: 28px;
      line-height: 32px;
    }
    .ant-modal-title {
      display: none;
    }
    .ant-modal-footer {
      background-color: #ffffff;
    }
    &.inmodal-page-loading {
      .ant-modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 85%;
        iframe {
          position: absolute;
          z-index: -10;
          display: none;
        }
      }
    }
  }
}

.ant-message {
  .ant-message {
    &-notice {
      &-content {
        border: 1px solid RGB(0 0 0 / 25%);
        box-shadow: RGB(0 0 0 / 25%) 0px 4px 4px;
      }
    }
  }
}

.reconcilation-wrapper {
  .reconcile-report {
    .button {
      &--edit {
        background-color: #fff;
        color: #462E90;
        border-color: #462E90;
      }
    }
  }

  .match-entry {
    &__confidence {
      // font-size: 15px;
      line-height: 18px;
      font-weight: 600;
    }
  }
}

.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: #fff;
  opacity: 0.4;
}

.ant-btn-disabled,
.ant-btn:not([disabled]) {
  &:hover {
    border-color: inherit;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
  }
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: inherit;
}

.ant-layout-sider {
  .ant-menu-item {
    span.beta-tag-wrapper {
      font-size: 10px;
      position: absolute;
      // left: 16px;
      // bottom: 8px;
      // text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
      // text-shadow: 2px 2px 2px #000;
      right: 16px;
      transform: none;
      top: -9px;
      transition: all 0.1s ease-in;
      font-weight: bold;
      .beta-tag,
      .beta-tag-collapsed {
        padding: 2px 5px;
        border-radius: 50%;
        background-color: #fff;
        color: green;
        opacity: 1;
        transition: all 0.1s ease-in;
        &-collapsed {
          opacity: 0.001;
          padding: 1px 6px;
          position: relative;
          left: -32px;
        }
      }
    }
    &-selected {
      span.beta-tag-wrapper {
        .beta-tag,
        .beta-tag-collapsed {
          color: #fff;
          background-color: green;
        }
      }
    }
  }
  &.ant-layout-sider-collapsed {
    span.beta-tag-wrapper {
      right: -24px;
      .beta-tag {
        opacity: 0.0001;
        &-collapsed {
          opacity: 1;
        }
      }
    }
  }
  &:not(.ant-layout-sider-collapsed){
    box-shadow: 0 5px 10px #000;
  }
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: inherit;
}

.prospect-table__actions__whatsapp {
  text-align: center;
  img {
    max-width: 25px;
    cursor: pointer;
  }
}

.ant-message-notice-content {
  .ant-message-custom-content {
    display: flex;
    align-items: center;
    // text-align: left;
    .anticon {
      font-size: 18px;
      &+span{
        position: relative;
        top: 1px;
      }
    }
    &.ant-message-error{
      color: #ff4d4f;
      font-weight: 600;
    }
  }
}

.signup-success{
  &__page{
    h2{
      color: rgb(37, 65, 157);
      margin-bottom: 1em;
    }
    .text{
      &--timer{
        width: 12px;
        color: rgb(255, 95, 79);
        display: inline-block;
      }
    }
  }
}

.heading{
  &--lined{
    .line{
      display: block;
    }
  }
}
.hidden{
  display: none !important;
}

.floating-wpp-button,
.floating-wpp-popup{
  display: none;
}

#WAButton{
  .floating-wpp-button{
    display:none;
    &:first-child{
      display: block;
    }
  }
  .floating-wpp-popup{
    display: none;
    &:nth-child(2){
      display: block;
    }
  }
}

.select-dropdown
 {
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
  }

 }