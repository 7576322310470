


.ant-picker-input span.ant-picker-suffix{
    color:#462E90;
}


.anticon.anticon-down svg {
    position: relative;
    top: 4px;
}
.recon-report-container {
    width: 100%
}
.recon-report-container .ant-card-body{
    width: 100%
}
.recorn-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.recorn-card .title{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

.recorn-card .subtitle{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    color: #5C5C5C;
}
.recorn-card .orange-btn {
    height: 52px;
    background: #462E90;
    border-radius: 5px;
    font-weight: bold;
    color: #ffffff;
    border-radius: 5px
}
.recorn-card .white-btn {
    height: 52px;
    background: #ffffff;
    border-radius: 5px;
    font-weight: bold;
    color: #462E90;
    border-radius: 5px
}
