
// ///////////////////////Login/////////////////////
.mobile-logo{
    height: 8vh;
}
.login_column_1 {
    flex:50%;
}
.login_column_2 {
    flex:50%;
}
.login_row{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.help_btn_link{
    font-size: 16px;
}
.about_product{
    font-size: 16px;
}
.need_HELP_text{
    font-size: 16px;

    &--hide {
        visibility: hidden;
    }
}
.need_HELP_text2{
    font-size: 16px;
}

#login_img{
    display: flex;
    justify-content: center;
    height:100vh;
    background-image: linear-gradient(to bottom, rgba(98, 98, 98, 0.01) -14%, rgba(25, 25, 25, 0.76)),url('../images/login_image.jpg');
    background-position: center;
    background-repeat: no-repeat ;
    background-size:cover;
    position: relative;
}
.welcome-title{
    font-size: 2.5rem;
    color: #5c5c5c;  
    font-weight: 500;
}
.tagline-kyss{
    margin-top: -15px;
    width:73%;
    font-size: 1rem;

}
#text{
    position: absolute;
    bottom: 100px;
    margin:0 auto;
    color:#fff;
    font-size: 2.5rem;
}
.product_info{
    display: flex;
    bottom:20px;
    justify-content: space-between;
    text-align-last: right;
    position: absolute;
    width:50%;
}
.mobile_img{
    height:26px;
    display:none;
}
.Login-box-row{
    margin-top:6rem;
}

.need_help_link{
    margin-right:2rem;
}

.register_row{
    align-content: center;
    margin-top: -3.7rem;
}

////////////////////Register//////////////////
.image-style{
    padding-right: 20px;

}

.signup_column_1 {
    display: grid;
    justify-content: center;
    padding:0;

}
.already-have-acc{
    padding:0px;
}
.already-acc-text{
    color:#707070;
    padding-right: 10px;
    font-size: 1rem;
}
.login-here-link{
    font-size: 1rem;
    color:#1a83ba;
    line-height: normal;
}
.signup_column_2{
    height: 100vh;
}
.mobile_img_reg{
    height:26px;
    display:none;
}
.dont-have-acc{
    padding-right: 10px;
    font-size:16px;
    color:#707070;
}
.product_info_reg{
    display: flex;
    justify-content: space-between;
    text-align-last: right;
    position: absolute;
    bottom:20px;
    width:50%;
}
#signup_img{
    background-color: #462e90;
    background-position: center;
    background-repeat: no-repeat ;
    background-size:cover;
    position: relative;

}
.signup_row{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.ant-carousel .slick-slider{
    display: grid;
    height:100;
}
#signup_text{
    position: absolute;
    bottom: 70px;
    width:60%;
    margin:0 auto;
    color:#ffff;
}
.already-account{
    align-content: center;
    margin-top: -3.7rem;
}

.TextArea{
    padding-top: 10%;
}
.ant-col .ant-col-24 {

    text-align:center;
    align-items:center;
    display:flex;
    flex-direction:column;

}

.ant-carousel .slick-dots li button{
    height: 13px;
    width:13px;
    border-radius: 100%;
    display: block;
    background-color:#fff;
    cursor: pointer;
    transition: none;
}
.ant-carousel .slick-dots li .slick-active{
    height: 13px;
    border:solid 1px #fff;
    transition: none;
}
.ant-carousel .slick-dots li.slick-active{
    width: 2%;
    border-radius: 100%;
}
.ant-carousel .slick-dots li button:hover{
    opacity: 0.7;
}

.loginimg_text{
    width: 25rem;
}
.ant-carousel .slick-dots{
    gap: 7px;
    display: flex;
    justify-content: center;
    position: initial;
}
.carouseldiv{
    margin-top: -1px;
}
.carousel-text-1{
    color:#ffff;
    font-size:2.5rem;
    margin-top:0vh;
}
.carousel-text-2{
    color:#ffff;
    font-size:2.5rem;
    margin-top: 2vh;
}
.carousel-text-3{
    color:#ffff;
    font-size:2.5rem;
    margin-top: -6vh;
}
.carousel_img1{
    width: 100%;
}
.carousel_img2{
    width: 100%;
    padding: 30px 20px 15px 30px;
}
.carousel_img3{
    width: 100%;
    margin-top: -1rem;
}
.carasoul-tag-line-1{
    display: inline-block;
    color:  rgba(255, 255, 255, 0.69);
    font-size: 1rem;
    width: 60%;
    margin-top: -8vh;
}
.carasoul-tag-line-2{
    display: inline-block;
    color:  rgba(255, 255, 255, 0.69);
    font-size: 1rem;
    width: 60%;
    margin-top: -7vh;
}
.carasoul-tag-line-3{
    display: inline-block;
    color:  rgba(255, 255, 255, 0.69);
    font-size: 1rem;
    width: 60%;
    margin-top: -5vh;
}
.help_link_reg{
    margin-right:2rem;
}

.register-here-style{
    font-size:16px;
}

@media screen and (max-width:768px) {

    .login_row{
        display: flex;
        height:100vh;
        flex-direction:column ;
    }
    .mobile_img{
        display: block;
        height: 1.8rem;
    }
    #login_img{
        display: initial;
        justify-content: center;
        height:100vh;
        background-image: linear-gradient(to bottom, rgba(98, 98, 98, 0.01) -14%, rgba(25, 25, 25, 0.76)),url('../images/login_image.jpg');
        background-position: center;
        background-repeat: no-repeat ;
        background-size:cover;
        position: relative;
    }
    .product_info{
        text-align-last: left;
        justify-content: flex-start;
        margin-left: 1.75rem;
        bottom:8px;
        flex-direction: column;
    }
    .Jitfinlogo{
        display: none;
    }
    .loginimg_text{
        display: none;
    }
    #text{
        width: 100%;
        text-align-last:left;
        padding-left:2rem;
        margin:0 0 -4rem 0;
        font-size: 1.7rem;
    }
    .Login-box-row{
        margin-top: 1rem;
        padding-left: 1.2rem;
    }
    .need_HELP_text{
        font-size: 0.8rem;
    }
    .need_HELP_text2{
        font-size: 0.8rem;

        &--mb {
            margin-bottom: 0.5rem;
        
        }
    }
    .welcome-title{
        font-size: 1.8rem;
    
    }
    .tagline-kyss{
        font-size: 0.8rem;
        width: 16rem;
    }
    .regcallout_reg{
        margin-top: -12rem;
        margin-left: 1.2rem;
    }
    .dont-have-acc{
        font-size:0.8rem;
        color:#707070;
    }
    .register-here-style{
        color:#1a83ba;
        font-size:0.8rem;
        text-decoration-line:underline;
    }
    .ant-form .ant-form-item .ant-form-item-control{
        flex:0 0 80%;
    }
    
}

@media screen and (max-width:768px) {
    .signup_column_1{
        flex: 50% 1;
    }
    .TextArea{
        padding-top: 0;
    }
    .already-acc-text{
        font-size: 0.8rem;
    }
    .already-have-acc{
        margin-top: -6.7rem;
    }
    .login-here-link{
        font-size: 0.8rem;
    }
    .signup_row{
        display: flex;
        flex-direction: column;
        height:100vh;
    }
    .mobile_img_reg{
        display: block;
        height: 1.8rem;
    }
    .carousel-text{
        color: #ffff;
        font-size: 26px;
    }
    .already-account{
        margin-left: 1.3rem;
        margin-bottom:2rem;
    }

    .carouseldiv{
        width: 100%;
    }
    .signup_column_2{
        flex:50% 1;
    }
    .Jitfinlogo_reg{
        display: none;
    }
    .ant-col .ant-col-24 {
        line-height: 0.8;
    }
    .ant-carousel .slick-dots{
        bottom:95px;
    }
    .carousel_img1{
        padding:0px;
    }
    .help_btn_link{
        font-size: 0.8rem;
    }
    .about_product{
        font-size: 0.8rem;
    }
    .carousel_img2{
        padding-left: 30px;
        margin-top: -0.8rem;
        padding-right:30px;

    }
    .carousel_img3{
        // height: 55vh;
        margin-bottom: -20px;
    }
    .product_info_reg{
        text-align-last: left;
        margin-left: 1.75rem;
        justify-content: flex-start;
        bottom:8px;
        flex-direction: column;

    }
    .carasoul-tag-line-1{
        display: none;
    }
    .carasoul-tag-line-2{
        display: none;
    }
    .carasoul-tag-line-3{
        display: none;
    }
    .carousel-text-1{
        font-size: 22px;
    }
    .carousel-text-2{
        font-size: 22px;
    }
    .carousel-text-3{
        font-size: 22px;
        margin-top: -0.2vh;
    }
    .help_btn_link_2{
        margin-top: -10px;
    }
    .register_row{
        align-content: center;
        margin-top: 0px;
    }
    .ant-carousel .slick-dots li button{
        height: 8px;
        width:8px;
        border-radius: 100%;
        display: block;
        background-color:#fff;
        cursor: pointer;
        transition: none;
    }
    .ant-carousel .slick-dots li{
        text-align: center;
        padding: 0;
    }
}
@media screen and (max-width:768px) and (orientation:landscape){
    .product_info_reg{
        margin-left:2.5rem;
        bottom: auto;
    }
    .product_info{
        margin-left:2.5rem;
        bottom: auto;
        margin-top: -3rem;
    }
    .mobile-logo{
        height: 100vh;
    }
    .image-style{
        padding-right: 20px;
        margin-top: -19rem;
    }

}
@media screen and (orientation:landscape) and (max-height:500px){
    #text{
        position: absolute;
        font-size: 2rem;
        padding-left: 1.5rem;
    }
    .welcome-title{
        font-size: 2rem;
    }
    .Login-box-row{
        margin-top: 3rem;
    }
    .tagline-kyss{
        width:90%;
    }
    .carousel-text-1{
        font-size: 2rem;
    }
    .carousel-text-2{
        font-size: 2rem;
    }
    .carousel-text-3{
        font-size: 2rem;
    }
    .login_row{
        height:149vh;
    }
    #login_img{
        height:149vh;
    }
    .Jitfinlogo{
        margin-top:1rem;
    }
    .Jitfinlogo_reg{
        margin-top:1rem;
    }
}
@media screen and (width:768px) and (height:1024px){

    .product_info{
        margin-left:3rem;
    }
    .product_info_reg{
        margin-left:3rem;
    }
    .Login-box-row{
        padding-left: 0;
    }
    .already-account{
        margin-left: 0;
    }
    .regcallout_reg{
        margin-left: 0;
    }
}
