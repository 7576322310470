.ant-radio-checked .ant-radio-inner{
    border-color:#462e90 !important ;
  }
  
  .ant-radio-checked .ant-radio-inner:after{
    background-color: #462e90;
  }
  
  .ant-radio:hover .ant-radio-inner {
    border-color: #462e90 ;
  }

  .card {
    width: 40%;
    margin: 6rem auto; 
    padding: 0rem 2rem;
    box-shadow: 5px 8px 5px 0px rgba(252, 246, 246, 0.75);


  }

  .badge {
    margin-left: 5px;
  }

  .badge-span {
    background-color: lightgrey;
    color: white;
    cursor: not-allowed;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 50px;
    font-size: x-small;
  }
 
  @media (max-width:575px){
    .card {
      .ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control {
        flex: 0 0 100%;
      }
    }
  }
  @media (max-width: 768px) {
    .card {
      width: 100%;
      padding: 0rem 0.5rem;
      margin: 4rem auto; 
    }
   
  }