.reconcilation-wrapper {
  .row {
    background: #ffffff;
    height: 60px;
  }

  .row-gray {
    background: #f6f6f6;
    height: 60px;
  }

  .page-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
  }

  .page-subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  .ant-tabs-nav-wrap {
    box-shadow: rgba(69, 50, 191, 0.3) 3px 3px 10px 0px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4532bf !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-tabs-tab:hover {
    color: #4532bf !important;
    // font-size: 16px;
    // line-height: 24px;
  }

  .download-gst-r2a-wrapper {
    .title {
      font-weight: 500;
      color: #000;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    .subtitle {
      color: #393939;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 15px;
    }

    .img-text {
      color: #000000;
      position: absolute;
    }

    .next-btn {
      min-width: 320px;
      min-height: 52px;
      background: #ff5f4f;
      border-radius: 5px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 100px;
      cursor: pointer;
    }

    .start-recon-btn {
      min-width: 340px;
      min-height: 52px;
      background: #ff5f4f;
      border-radius: 5px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
      z-index: 9;
    }

    .recon-change{
      &__wrapper{
        text-align: center;
      }
      &__btn{
        color: #4532BF;
        text-decoration: underline;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .generate-opt-wrapper {
    .text {
      color: #000;
    }

    .link {
      font-weight: 600;
      color: #4532bf;
      text-decoration: underline;
    }

    .enable-opt-checkbox {
      color: #000;

      .ant-checkbox {
        border: 2px solid #4532bf;
        box-sizing: border-box;
        border-radius: 5px;
        height: 20px;
        width: 20px;
      }

      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #4532bf;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #4532bf;
        border-color: #4532bf;
      }
    }

    .divider {
      border: 1px solid #e3e3e3;
      width: 60%;
    }

    .generate-title {
      font-weight: 500;
      color: #000;
    }

    .generate-subtitle {
      color: #393939;
    }

    button {
      min-width: 320px;
      min-height: 52px;
    }

    .orange-btn {
      background: #ff5f4f;
      border-radius: 5px;
      font-weight: bold;
      color: #ffffff;
    }

    .orange-btn:hover {
      border-color: #ff5f4f;
    }

    .orange-btn[disabled],
    .orange-btn[disabled]:hover,
    .orange-btn[disabled]:focus,
    .orange-btn[disabled]:active,
    .orange-outline-btn[disabled],
    .orange-outline-btn[disabled]:hover,
    .orange-outline-btn[disabled]:focus,
    .orange-outline-btn[disabled]:active {
      color: #fff;
      background: #d6d6d6;
      border-radius: 5px;
      border-color: #d6d6d6;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .orange-outline-btn {
      color: #ff5f4f;
      border: 1px solid #ff5f4f;
      border-radius: 5px;
      font-weight: bold;
      background: #ffffff;
    }

    .otp-wrapper {
      align-items: flex-end;
    }

    .otp-input {
      margin-left: 10px;

      input {
        width: 2rem !important;
        height: 2rem !important;
        background: #ffffff;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }
  }

  .gst-downloaded {
    .title {
      color: #000;
      font-weight: 500;
    }
    button {
      min-width: 320px;
      min-height: 52px;
    }

    .orange-btn {
      background: #ff5f4f;
      border-radius: 5px;
      font-weight: bold;
      color: #ffffff;
    }

    .orange-btn:hover {
      border-color: #ff5f4f;
    }
  }

  .mapping-gst-r2a-wrapper {
    .white-selector {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 278px !important;
        height: 45px !important;
        background: #ffffff !important;
        color: #4532bf !important;
        border-radius: 4px !important;
        box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.04),
          0px 4px 10px rgba(0, 0, 0, 0.06);
        border-radius: 5px;
      }

      .ant-select-selection-item {
        color: #4532bf !important;
        font-weight: bold !important;
        font-size: 20px;
        display: flex;
        align-items: center;
      }
    }

    .gray-selector {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        min-width: 200px;
        height: 36px;
        background: #e6e6e6;
        border-radius: 20px;
      }

      .ant-select-selection-item {
        color: #4532bf;
        font-weight: bold;
        display: flex;
        align-items: center;
      }

      // .ant-select-arrow {
      //   margin-right: -40px;
      //   margin-top: -11px;
      // }
    }

    .title {
      font-weight: 500;
      color: #000;
    }

    .subtitle {
      color: #393939;
    }

    .img-text {
      color: #000000;
      position: absolute;
    }

    .next-btn {
      min-width: 320px;
      min-height: 52px;
      background: #ff5f4f;
      border-radius: 5px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 100px;
    }

    .start-recon-btn {
      min-width: 340px;
      min-height: 52px;
      background: #ff5f4f;
      border-radius: 5px;
      font-weight: bold;
      color: #ffffff;
    }
  }

  .ant-upload-list-item {
    position: relative !important;
    height: 22.001px !important;
    margin-top: -70px !important;
    font-size: 14px !important;
    display: flex !important;
    justify-content: center !important;
  }

  .ant-upload-list-item-name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    color: #000000;
  }

  .none-file .ant-upload-list-item-name {
    display: none !important;
  }

  .ant-upload-text-icon {
    display: none;
  }

  .ant-upload-list-item-card-actions {
    display: none;
  }

  .record-summary-wrapper {
    .ant-card-body {
      width: 100% !important;
      padding: 20px 30px !important;
    }

    .next-btn {
      min-width: 320px;
      min-height: 52px;
      background: #ff5f4f;
      border-radius: 5px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 30px;
    }
  }

  .reconcile-report {
    .blue-card {
      background: #F4F7FF;
      border-radius: 5px;
      color: black;
      border-style: solid;
      border-width: 0.8px;
      border-color: #7EA6FF;
    }

    .specRow {
      border: 2px solid rgb(212, 212, 212);
      padding: 10px;
      height: 115px;
    }

    .border-bottom {
      border-bottom: 1px solid #e3e3e3;
    }

    .red-btn {
      min-height: 40px;
      background: #462E90;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      font-weight: bold;
      color: #ffffff;
    }

    .green-btn {
      min-height: 40px;
      background: #F1FFF0;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      font-weight: bold;
      color: #258921;
      border:solid 1px #258921;
      &:hover{
        color: #258921;
      }
    }

    .disabled-btn {
      min-height: 40px;
      background: #afafaf;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      font-weight: bold;
      color: #646464;
    }

    .search-input {
      display: flex;
      border-radius: 5px;
      width: 100%;
      padding: 10px;
      margin: 10px 0px;
      border: 1px solid #d5d5d5;
      background: transparent;
      :focus {
        border: 1px solid #d5d5d5;
        outline: unset;
      }
    }

    .red-text {
      color: #ff5f4f !important;
    }

    .orange-text {
      color: #ffae42 !important;
    }

    .green-text {
      color: #00b83f;
    }

    .white-btn {
      min-height: 40px;
      background: #ffffff;
      border: 1px solid #ff5f4f;
      border-radius: 5px;
      font-weight: bold;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
      color: #ff5f4f;
    }

    .bl {
      border-left: 1px solid #d4d4d4;
    }

    .br {
      border-right: 1px solid #d4d4d4;
    }

    .bt {
      border-top: 1px solid #d4d4d4;
    }

    .bb {
      border-bottom: 1px solid #d4d4d4;
    }

    .brl {
      border-radius: 5px 0px 0px 5px;
    }

    .brr {
      border-radius: 0px 5px 5px 0px;
    }

    .thead {
      background: rgba(69, 50, 191, 0.15);
      border-radius: 5px;

      th {
        padding: 25px 15px;
      }

      th:first-child {
        border-radius: 5px 0px 0px 5px;
      }

      th:last-child {
        border-radius: 0px 5px 5px 0px;
      }
    }

    .ant-select-arrow {
      margin-top: -13px;
    }

    .ant-select-selector {
      background: transparent;
      border: 0px;
      min-width: 125px;
      .ant-select-selection-placeholder {
        color: black;
        font-weight: 700;
        font-size: 14px;
      }
    }

    .red-select {
      .ant-select-arrow {
        margin-top: -9px;
      }

      .ant-select-selector {
        background: #462E90;
        border: 0px;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
        border-radius: 5px;
        min-width: 125px;
        .ant-select-selection-placeholder {
          color: white;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }

    .table {
      width: 100%;
    }

    .tbody {
      .trow {
        box-sizing: border-box;
        border-radius: 5px;
      }
    }
  }
  .ant-btn-disabled,
  .ant-btn.disabled,
  .ant-btn[disabled],
  .ant-btn-disabled:hover,
  .ant-btn.disabled:hover,
  .ant-btn[disabled]:hover,
  .ant-btn-disabled:focus,
  .ant-btn.disabled:focus,
  .ant-btn[disabled]:focus,
  .ant-btn-disabled:active,
  .ant-btn.disabled:active,
  .ant-btn[disabled]:active,
  .ant-btn-disabled.active,
  .ant-btn.disabled.active,
  .ant-btn[disabled].active {
    color: #258921;
    opacity: 0.6;
  }
}

.reconcile {
  &__report {
    .table__pagination {
      margin-top: 15px;
      .pagination {
        display: -ms-flexbox;
        display: flex;
        padding-left: 0;
        list-style: none;
        border-radius: 0.25rem;
      }

      .page-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #007bff;
        background-color: #fff;
        border: 1px solid #dee2e6;
      }

      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }

      .page-item {
        &.active .page-link {
          z-index: 3;
          color: #fff;
          background-color: #007bff;
          border-color: #007bff;
        }
        &:not(.disabled):not(.active) {
          .page-link {
            cursor: pointer;
          }
        }
        &.disabled .page-link {
          background: #dee2e6;
          border: 1px solid #dee2e6;
        }
      }
    }
  }
}
.green-btn-modifier {
  background: #F1FFF0;
  color: #258921;
  border:solid 1px #258921;
  &:hover{
    color: #258921;
  }
}
