//0px 4px 4px rgba(0, 0, 0, 0.25)

.einvoicing-main {
  .ant-tabs-bar {
    border-bottom: 2px solid #f0f0f0;
  }
}

.warning-banner {
    color: red;
    padding: 10px;
    background: #eee;
    border: 1px solid;
    border-radius: 5px;
}

.edashboard {
  &__btnbox {
    &__btn {
      font-weight: bold;
      border-radius: 5px;
    }
  }
}
.dashboard-threecolgrid {
  width: 90%;
  margin: 3rem auto 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8rem;
  row-gap: 4rem;

  &:last-child {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 1100px) {
  .dashboard-threecolgrid {
    column-gap: 2rem;
    row-gap: 4rem;
  }
}

@media (max-width: 768px) {
  .dashboard-threecolgrid {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}
.einv_card {
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &__title {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1;
  }

  &__subheading {
    &--bold {
      font-weight: bold;
      color: #000;
      font-size: 1rem;
      margin: 0 2px 0 0;
    }
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 3rem;

    &__text {
      margin-bottom: 0px;
    }

    &__icon {
      // font-size: 1.5rem;
      cursor: pointer;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }
}
@media (min-width: 768px) and (max-width: 1100px) {
  .einv_card {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .einv_card {
    margin: 0 auto;
    &__title {

      line-height: 1.5;
    }
  }
}

//Invoice List
.invoice-section {
  .ant-tabs-bar {
    border-bottom: 1px solid #f0f0f0;
  }
}
.invoice-btnbox {
  display: flex;
  align-items: center;
  column-gap: 3rem;
  margin-bottom: 2rem;

  &__btn {
    font-weight: bold;
    border-radius: 5px;
    &--bulkbtn {
      background-color: #d9ffff;
      color: #4532bf;
    }
  }
}
@media (max-width: 768px) {
  .invoice-btnbox {
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
}

//Invoice list >> IRN pending

.irn-container {
  margin: 1rem 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &__firstele {
    display: flex;
    align-items: flex-end;
    column-gap: 1.3rem;

    &__datepicker {
      &--text {
        margin-bottom: 0;
      }
    }
    &__btn {
      font-weight: bold;
      border-radius: 5px;
    }
  }
  &__secondele {
    display: flex;
    align-items: center;
    column-gap: 1.3rem;
    &__text {
      margin-bottom: 0;
    }
    &__btn {
      font-weight: bold;
      border-radius: 5px;
    }
    &__text {
      color: #4532bf;
      border-bottom: 1px solid #4532bf;
      line-height: 0.9;
      font-weight: bold;
    }
  }
}
@media (max-width: 768px) {
  .irn-container {
    flex-direction: column;
    align-items: flex-start;
    &__firstele {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 0.5rem;
    }
    &__secondele {
      margin-top: 1rem;
    }
  }
}
.actions-text {
  color: #4532bf;
  border-bottom: 1px solid #4532bf;
  line-height: 0.9;
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
  margin-bottom: 0;
}

//Invoice list >> Error list
.err-container {
  margin: 1rem 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &__firstele {
    display: flex;
    align-items: flex-end;
    column-gap: 1.3rem;

    &__datepicker {
      &--text {
        margin-bottom: 0;
      }
    }
    &__text {
      color: #4532bf;
      border-bottom: 1px solid #4532bf;
      line-height: 0.9;
      font-size: 1rem;
      font-weight: bold;
      margin: 1.2rem 0 0 0;
      align-self: center;
      cursor: pointer;
    }
  }
  &__secondele {
    display: flex;
    align-items: center;
    column-gap: 1.3rem;
    &__text {
      margin-bottom: 0;
    }
    &__btn {
      font-weight: bold;
      border-radius: 5px;
      background-color: #d9ffff;
      color: #4532bf;
    }
    &__text {
      color: #4532bf;
      border-bottom: 1px solid #4532bf;
      line-height: 0.9;
      font-weight: bold;
    }
  }
}
@media (max-width: 768px) {
  .err-container {
    flex-direction: column;
    align-items: flex-start;
    &__firstele {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 0.5rem;
      &__text {
        align-self: flex-start;
      }
    }
    &__secondele {
      margin-top: 1rem;
    }
  }
}

//Drafts
.draft-tabs {
  .ant-tabs-bar {
    border-bottom: 1px solid #f0f0f0;
  }
}
.iconContainer {
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  &--column-gap {
    column-gap: 0.5rem;
  }

  &__item {
    margin: 0 10px 0 0;
    cursor: pointer;

 

    &:hover {
      color: #4532bf;
    }
  }
  .actions-text {
    font-size: 14px;
  }
}
//File Upload Section CSS

.uploadSection {
  transition: all 0.3s ease-in-out;
  &__title {
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
  }

  &__done {
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    &__text {
      color: #008080;
    }
  }

  &__download {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
      color: #4532bf;
      border-bottom: 1px solid #4532bf;
      line-height: 0.9;
      font-weight: bold;

      &:hover {
        color: #4532bf;
      }
    }
    &__btn {
      color: white;
      border-radius: 5px;
      font-weight: bold;
    }
  }
}
.fileupload-section {
  animation: moveInFromLeft .3s ease-in-out;
  .ant-upload.ant-upload-drag {
    width: 50%;
    margin: 0 auto;
    padding: 2rem 0;
  }
  &__title {
    text-align: center;
  }

  &__dragger {
    margin-top: 2rem;
    &__text {
      color: rgba(0, 0, 0, 0.45);
      font-size: 1.5rem;
      text-align: center;
    }
  }

  &__browse {
    margin-top: 1rem;
    // margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__btn {
      font-size: 1.2rem;
      font-weight: bold;
      background-color: #4532bf;
      color: white;
      border-radius: 5px;
      padding-bottom: 2rem;
    }
  }
}

@media (max-width: 768px) {
  .fileupload-section {
    .ant-upload.ant-upload-drag {
      width: 80%;
    }
  }
}
.success-section {
  animation: moveInFromLeft .3s ease-in-out;
  &__icon {
    text-align: center;
    margin-top: 2rem;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      padding: 1.2rem;
      background-color: #52c41a;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      transform: translate(-50%, -20%);
      border-radius: 50%;
      opacity: 0.3;
    }
  }
  &__text {
    text-align: center;
    margin: 2rem 0;
    font-weight: bold;
  }
  &__btnbox {
    text-align: center;

    &__btn {
      background-color: #4532bf;
      color: white;
      font-weight: bold;
      border-radius: 5px;
      padding:  0 1.5rem;

    }
  }
}
//Animations 
@keyframes moveInFromLeft {
  0% {
    transform: translateX(-10rem);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
  
}

.irn-bg-changes {
  .ant-tabs-tab-active{
  background-color: #EBE5FF;
  padding-left: 5px;
  padding-right: 5px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 12px;
  }
}

.select-border-radius {
  .ant-select-selector {
    border-radius: 5px !important;
  }
}

.date-picker-border-radius {
  border-radius: 5px !important;
}

.cursor {
  &:hover {
    cursor: pointer;
  }
}
.no-pointer-event{
  pointer-events: none;
}