.download-wrapper {
    .row{
      background: #FFFFFF;
      height: 60px;
    }
  
    .ant-card-body{
      width:100%!important;
      display: flex;
    }

    .title{
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }

    .subtitle{
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */
      color: #5C5C5C;
    }

    .row-gray{
      background: #F6F6F6;
      height: 60px;
    }
  
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
      width: 130px;
      height: 36px;
      background: #E6E6E6;
      border-radius: 20px;
    }  
  
    .ant-select-selection-item{
      color: #4532BF;
      font-weight: bold;
    }
  
    .page-title {
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
    }
  
    .page-subtitle {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
  
    .ant-tabs-nav-wrap {
      box-shadow: rgba(69, 50, 191, 0.3) 3px 3px 10px 0px;
    }
  
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #4532bf !important;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  
    .ant-tabs-tab-btn {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  
    .ant-tabs-tab:hover {
      color: #4532bf !important;
      // font-size: 16px;
      // line-height: 24px;
    }

    .orange-btn {
      height: 52px;
      background: #ff5f4f;
      border-radius: 5px;
      font-weight: bold;
      color: #ffffff;
      border-radius: 5px
    }

    .white-btn {
      height: 52px;
      background: #ffffff;
      border-radius: 5px;
      font-weight: bold;
      color: #ff5f4f;
      border-radius: 5px
    }
  }
  