.header {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  &__icon {
    font-size: 1.3rem;
    margin: 0;
    color: black;
    cursor: pointer;
    font-weight: bold;
  }

  &__title {
    font-size: 1.3rem;
    margin: 0;
    color: black;
    font-weight: bold;
  }
}

.customer {
  margin-top: 2rem;

  &__title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0px;
  }

  &__card {
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1.5rem;
    }
    .ant-form-item-label > label {
     width: 150px;   
    }
  }
}

@media (max-width: 768px) {
//   .header {

//   }

  .customer {
    &__card {
      &__grid {
        grid-template-columns: 1fr;
        row-gap: 0.5rem;
      }
    }
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 50%;
  }
}
@media (max-width: 575px) {
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 50%;
  }
}
.formbtnbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 2rem;
  margin-top: 3rem;

  &__btn {
    border-radius: 5px;
    font-weight: bold;
    &--paragraph {
      margin-bottom: 0px;
      font-size: 1rem;
      line-height: 0.9;
      border-bottom: 1px solid #4532bf;
      cursor: pointer;
      border-radius: 0px;
      transition: all 0.3s ease-in-out;
    }
  }
}
