.header {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  &__icon {
    font-size: 1.3rem;
    margin: 0;
    color: black;
    cursor: pointer;
    font-weight: bold;
    margin-top: 0.4rem;
  }

  &__title {
    font-size: 1.3rem;
    margin: 0;
    color: black;
    font-weight: bold;
    margin-left: 0.5rem;
  }
}

.header-details {
  display: flex;
  align-items: center;
  column-gap: 2.5rem;

  &__primary {
    &--bold {
      font-weight: bold;
    }
  }

  &__text {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 768px) {
    .header-details {
      flex-direction: column;
      align-items: flex-start;
    }
  }
// .overview-detail-container {
//     display: flex;
//     align-items: center;
//     column-gap: 5rem;

//     &--bold {
//         font-weight: bold;
//     }
//  }
.overview-details {
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 100%;

  &__value {
    &--bold {
      font-weight: bold;
    }
  }
}
@media (max-width: 768px) {
  .overview-details {
    width: 90%;
  }
}

.overview-note {
  color: #1890ff;
  margin-top: 2rem;
}

.overview-table-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
}
.overview-table-grid * {
  overflow-x: auto;
}
@media (max-width: 768px) {
  .overview-table-grid {
    grid-template-columns: 1fr;
  }
}

//GSTIN Details
.gstin-details-grid {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    width:50%;

    &--bold {
        font-weight: bold;
    }
}
@media (max-width: 768px) {
    .gstin-details-grid {
        width:90%;
    }
  }

.gstin-heading {
    margin-top: 2rem;
}
//Filing status
.filter-container {
    display: flex;
    align-items: center;
    column-gap: 2rem;

    &__subheading {
        font-size: 1rem;
    }

    &__datepicker {

        &--label {
            margin-bottom: 0px;
        }
    }
}

@media (max-width: 768px) {
    .filter-container {
        flex-direction: column;
        &__orblock h4{
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
   
  }

.filing-card-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // grid-template-columns: 1fr 1fr 1fr;

    &__col {
        width: 20%;

        &--bold {
            font-weight: bold;
        }
    }
}
@media (max-width: 768px) {
    .filing-card-wrapper { 
        flex-direction: column;
        align-items: flex-start;
        &__col {
            width: 100%;

        }
    }
   
  }

.margin-top-warning {
  margin-top: 2rem;
  text-align: center;
}