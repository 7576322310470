
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;

  &__firstele {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    column-gap: 1rem;

    &__text {
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
    }
    &__box{
      width: 170px;
      
    }
  }
  &__secondele {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
    font-size: 1rem;

    &__text {
      margin: 0;
    }

    &__input {
      width: 30%;
    }
    .ant-input {
      color: rgba(0, 0, 0, 0.7);
      font-weight: bold;
    }
  }
}
@media (max-width: 768px) {
  .header-container {
    // flex-direction: column;
    // align-items: flex-start;
    // row-gap: 1rem;
    // &__secondele {
    //     justify-content: flex-start;
    // }
    &__firstele {
      font-size: 1.2rem;
    }
    &__secondele {
      &__input {
        width: 50%;
      }
    }
  }
}
.radiotext {
  font-weight: bold;
}
.invoicetype-fourcolgrid {
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 0.8fr 1fr 1fr;
  column-gap: 3rem;
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .invoicetype-fourcolgrid {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
  }
}
@media (max-width: 768px) {
  .einvoice-form {
    .ant-form .ant-form-item .ant-form-item-label,
    .ant-form .ant-form-item .ant-form-item-control {
      flex: 0 0 50%;
    }
  }
}
@media (max-width: 575px) {
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 50%;
  }
}
.invDate {
  &__datepicker {
    width: 80%;
    border-radius: 5px;
  }
}
.dueDate {
  &__datepicker {
    width: 100%;
    border-radius: 5px;
  }
}

.reverse-chargeable {
  .ant-switch {
    font-weight: bold;
    background-color: #FF1700;
    font-size: 16px;
  }
  .ant-switch-checked {
    background-color: #25d366;
  }
}
.customer-name {
  .ant-select {
    color: rgba(0, 0, 0, 0.35);
  }
}
.invoicetype-threecolgrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
}
@media (max-width: 768px) {
  .invoicetype-threecolgrid {
    grid-template-columns: 1fr;
  }
}
.add-new {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-left: 1rem;

  &__text {
    margin: 0;
  }

  &__subcontainer {
    font-size: 1rem;
    color: #4532bf;
    font-weight: bold;
    &--small-font{
      font-size: 0.8rem;
    }

    &__text {
      display: inline-block;
      margin: 0 0 0 5px;
      border-bottom: 1px solid #4532bf;
      line-height: 0.9;
      cursor: pointer;
      font-weight: bold;

      
    }
    &__description {
      margin-top: 1rem;
      font-size:0.8rem;
    }
    &__descriptextarea {
      margin-top: 0.1rem;
    }
  }
}
@media (max-width: 768px) {
  .add-new {
    margin: 0 0 1rem 0;
  }
}
.invoicetype-twocolgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
}
.bill-phone {
  &__input {
    width: 70%;
  }
}
.ship-address {
  &__text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }
  &__checkbox {
    margin: 0 6px;
  }
}
@media (max-width: 768px) {
  .invoicetype-twocolgrid {
    grid-template-columns: 1fr;
  }
  .ship-address {
    margin-top: 3.5rem;
  }
}
.item-hsn-code {
  &__text {
    margin: 0;
    &--mt {
      margin-top: 0.5rem;
    }
  }
}
.tax {
  &__text {
    margin: 1rem 0 5px 0;
  }
  &__select {
    width:100%
  }
  &__percentage {
    width:100%
  }
  &__input {
    width: 80%;
  }
}
.table-add-row {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #4532bf;
  font-weight: bold;
  &__text {
    display: inline-block;
    margin: 0;
    border-bottom: 1px solid #4532bf;
    line-height: 0.9;
    cursor: pointer;
    font-weight: bold;

    &--disabled {
      pointer-events: none;
      cursor: default;
    }
  }
}
.table-row-bg {
  background-color:#f4f8ff;
  border-radius: 2px solid rgba(0,0,0,0.85);
}
.footer-twocolgrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 2rem;

  &__firstele {
    width: 85%;
    &--Bmargin{
      margin-bottom: 1.5rem;
    }
  }
  &__secondele {
    width: 100%;
    justify-self: flex-end;
  }
  .ant-form-item {
    margin: 0;
  }
}
.total-twocolgrid {
  display: grid;
  grid-template-columns: 65fr 35fr;
  column-gap: 2rem;
  row-gap: 2rem;
  align-items: center;
}
.total-label {
  margin: 0;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
 justify-self: flex-end;
 align-self: center;
}
@media (max-width: 768px) {
  .footer-twocolgrid {
    grid-template-columns: 1fr;
    &__firstele {
      width: 80%;
      margin-bottom: 2rem;
    }
    &__secondele {
      width: 100%;
      justify-self: flex-start;
    }
  }
  .total-label {
   justify-self: flex-start;
  }
  .total-twocolgrid {
    grid-template-columns: 70fr 30fr;
  }

}
.remove-row {

  &__icon {
    font-size: 1.2rem;
    color: #4532bf;
    cursor: pointer;
  }
}
.previewbtnbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 2rem;
  margin-top: 5rem;

  &__btn {
    border-radius: 5px;
    font-weight: bold;
    &--paragraph {
      margin-bottom: 0px;
      margin-bottom: 0px;
      font-size: 1rem;
      line-height: 0.9;
      border-bottom: 1px solid #4532bf;
      cursor: pointer;
      border-radius: 0px;
      transition: all 0.3s ease-in-out;
    }
  }
}
.search-item .search-unit{
  width: 100%;
}
.einvoice-form {
  .ant-form-item-label>label.ant-form-item-required::before{
    content: none;
 }
}
.dueDate_label{
  color: rgba(0, 0, 0, 0.80);
  font-variant: tabular-nums;
  font-size: 14px;
  line-height: 1.5715;
  margin-bottom: 10px;
}


.reverse-chargeble-switch {
  display: flex;
  width: 120px;
  background-color: #F8F9FD;
  height: 40px;
  border-radius: 2px;
  

  &__checked {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color:#5C5D67;
    box-shadow: 0px 0px 10px #747A9329;
    transform: scale(0.9);
    border-radius: 2px;
    font-size: 1rem;
    transition: all 0.3s ease-in;
    transform: scale(0.85);
    cursor: pointer;
  }
  &__unchecked{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #D1D1D1;
    transform: scale(0.9);
    border-radius: 2px;
    transition: all 0.3s ease-in;
    transform: scale(0.85);
    font-size: 1rem;
    cursor: pointer;
  }
}
.customer-search {
  .ant-form-item-label>label.ant-form-item-required::before{
    content: '*';
 }
}